
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TasksService } from 'src/services/tasks.service';
import { NotificationService } from 'src/services/notification.service';
import { AppService } from 'src/app/app.service';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from 'src/services/projects.service';
import { Constants } from 'src/services/constants.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from 'src/services/user.service';
import { GlobalVariable } from "src/app/globals";
import * as XLSX from 'xlsx';
  import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { Console } from '@angular/core/src/console';
declare var $;
@Component({
  selector: 'app-bugs',
  templateUrl: './bugs.component.html',
  styleUrls: ['./bugs.component.css']
})
export class BugsComponent implements OnInit {

  public counts: any = ""
  public ParamId = null;
  public boxToggle: boolean = false;
  public isTaskAdded: boolean = false;
  public isShowLogSlide: boolean = false;
  public isTasklogEdit: boolean = false;
  public projectList: any = [];
  public taskList: any = [];
  public userList: any = [];
  public taskListX :any =[];
  // public taskForm: FormGroup;
  public selectedAssignee: any = "";
  public selectedProject: any = "";
  public taskDetail: any;
  public taskDate: any;
  public taskStartDate:any;
  public totalCount: number = 0;
  // public maxDate: any;
  public loginId: number = 0;
  public loginUser: any = "";
  //task obj
  public title: any = '';
  public description: any = '';
  public projectId: number = 0;
  public assignedId: number = 0;
  public estimatedhours: number = 0;
  public dueDate:any;
  public addDate :any;
  public dueDatepicker: NgbDateStruct;
  public startDatePicker: NgbDateStruct;
  public sendEmail: boolean = false;
  public isBug: boolean = false;
  public assignedBy: string= '';
  public approvedBy: string = '';
  public isApproved : boolean = false;
  public attachDocumentName = [];
  formData: FormData = new FormData();
  files: FileList;
  //task log obj
  public taskLogId: number = 0;
  public taskId: number = 0;
  public logDatepicker: NgbDateStruct;
  //public logDatepickers: NgbDateStruct;
  public taskDateLog: any;
  public actualhours: number = 0;
  public logComment: any = '';
  public isTaskLogs = false;
  public search = "";
  bugStatuses: string[] = ['Pending', 'In Progress', 'To Do', 'Fixed', 'Re-Open','Review'];
  selectedBugStatus: string = 'To Do'; // Holds the selected status
   issue: number =0; 
  issueType: string[] = ['Bug', 'Suggestion'];
  issueTypes: string = 'Bug';

  sevverityValue: string[] = ['High', 'Medium', 'Normal','Critical','Crash'];
  sevverity: string = 'Medium';
  //edit log object
  public logDetail: any = '';
  //datatable
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  public pageNumber = 1;
  public pageSize = 25;
  public pageSizeX = 100000;
  public sortOrder = "desc";
  public sortBy = "DateAdded";
  // public search = "";
  public filterissueType='';
  public filterserverityType ='';
  public filterbugs ='';
  public filterAssignedId = 0;
  public filterAssignedById = 0;
  public filterProjectId = 0;
  public filterStatus = -1;
  public filterBug = -1;
  public filterDate = 0;
  public isDateExceeded = false;
  public nowDate = new Date();
  //upload attachments
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/taskfile?id=";
  public taskFileId = null;
  public toDayDate = moment().format('MM/DD/YYYY');//task.dueDate | localDate : 'MM/DD/YYYY'
  public date: any = moment().format('DD');
  public month: any = moment().format('MM');
  public year: any = moment().format('YYYY');
  public today: any;
  public date1: any = moment().format('DD');
  public month1: any = moment().format('MM');
  public year1: any = moment().format('YYYY');
  public today1: any;
  // public minDate = moment().year(this.year).month(this.month).date(this.date - 1).format('mm-dd-yyyy');

  //Followers multiple select
  public itemList = [];
  public selectedItems = [];
  public settings = {};
  public followers = [];

  public activeProjects: any = [];

  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private tasksService: TasksService,
    private userService: UserService,
    private noti: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private calendar: NgbCalendar,
    private http: Http,
    private el: ElementRef
  ) { }
  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Issue Tracker"
    });
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'admin' || this.loginUser.accessLevel == 'qa_engr' ) {
      this.loginId = 0;
    } else {
      this.loginId = this.loginUser.userId;
    }
    // console.log(this.loginId);
    this.ParamId = this.route.snapshot.queryParams.id;
    this.tasksCounts();
    this.getUser();
    this.getProject();
   
    this.settings = {
      singleSelection: false,
      text: "Select Participants",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      classes: "myclass participants-note",
      enableSearchFilter: true,
      addNewItemOnFilter: false
    };

    if (!!this.ParamId) {
      this.getTaskWithLogs(this.ParamId);
    } else {
      if (this.loginUser.accessLevel != 'super_admin') {
        // this.selectedItems.push({ "id": this.loginUser.userId, "itemName": this.loginUser.firstName, "profilePhoto": this.loginUser.profilePhoto })
        this.selectedAssignee = this.loginUser;
      }
    }

    
    if(this.date == "01"){
      this.today = { year: parseInt(this.year), month: parseInt(this.month), day: parseInt(this.date) };
    }
    else if(this.date == "02"){
      this.today = { year: parseInt(this.year), month: parseInt(this.month), day: parseInt(this.date) - 1 };
    }
    else{
      this.today = { year: parseInt(this.year), month: parseInt(this.month), day: parseInt(this.date) - 2 };
    }

    this.today1 = moment().format('YYYY-MM-DD');
    // if (this.date1 === "01") {
     
    //   this.startDatePicker = { year: parseInt(this.year1), month: parseInt(this.month1), day: parseInt(this.date1) };
    // } else if (this.date1 === "02") {
      
    //   this.startDatePicker = { year: parseInt(this.year1), month: parseInt(this.month1), day: parseInt(this.date1) - 1 };
    // } else {
     
    //   this.startDatePicker = { year: parseInt(this.year1), month: parseInt(this.month1), day: parseInt(this.date1) - 2 };
    // }
    $('[data-toggle="tooltip"]').tooltip();
    $(".list-inner-wrapper").click((e) => {  //use a class, since your ID gets mangled
      $(this).addClass("list-selected");      //add the class to the clicked element
    });

    this.getAllIssue();
    

  }


  
  
  

  isBugChange() {
    if (this.isBug) {
      $(".create-task-wrapper").addClass("bug-bg-color");
    } else {
      $(".create-task-wrapper").removeClass("bug-bg-color");
      
      this.selectedBugStatus = 'Pending'; 
      this.issue =0; 
      this.issueTypes= 'Bug';
      this.sevverity = 'Medium';
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  onItemSelect(item: any) {
  }

  OnItemDeSelect(item: any) {
  }

  tasksCounts() {
    this.tasksService.getIssueCounts(this.loginId).subscribe(response => {
      if (response.status == "ok") {
        this.counts = response.data;
     
      }
    }, error => {
      if (error.status == 401) { }
    });
  }


  approvedby(event: any){
    console.log("Checkbox state changed:", event.target.checked);
    this.someFunction(event.target.checked);
  }

someFunction(isChecked: boolean) {
  if (isChecked) {
    if(this.taskId != 0){
    this.tasksService.approvedTasks(this.taskId,this.loginUser.userId).subscribe(res => {
      if (res.status == "ok") {
        
        this.noti.notification("Success", "Task Approved Successfully", "success");
        this.showTasksDetails(res.data);

      }
    }, error => {
      if (error.status == 401) { }
    });
  }
  else{
    this.noti.notification("Error", "First Create Task ", "danger");
    
   this.resetTaskForm();
  }
  } else {
    if(this.taskId != 0){
    this.tasksService.approvedTasks(this.taskId,this.loginUser.userId).subscribe(res => {
      if (res.status == "ok") {

        this.showTasksDetails(res.data);
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
  else{
    this.noti.notification("Error", "First Create Task ", "danger");
   this.resetTaskForm();
  }
    
  }
}

  
  getAllIssue() {
    this.dtOptions = {
      // pagingType: 'full_numbers',
      pageLength: this.pageSize,
      //dom: 'rtlp',
       dom: 'Bfrtip',
      // order: [[0, 'desc']],
      "columnDefs": [
        { "width": "10%", "targets": 0 },
        { "width": "50%", "targets": 1, "orderable": false },
        { "width": "10%", "targets": 2, "orderable": false },
        { "width": "10%", "targets": 3, "orderable": false },
        { "width": "10%","targets": 4, "orderable": false },
      ],
      processing: false,
      serverSide: true, 
      // orderMulti: false,
      ajax: (dTParameters: any, callback) => {
        // $(".custom-loader").css("display", "table");
     
        this.pageSize = dTParameters['length'];
        this.pageNumber = dTParameters['start'] / this.pageSize + 1;
        //document.querySelector('.custom-loader').classList.add('visible');
        // this.sortOrder = dTParameters['order'][0].dir;
        this.search = dTParameters['search']['value'];
        // let column = dTParameters['order'][0].column;
        // switch (column) {
        //   case 0:
        //     this.sortBy = "DateAdded";
        //     break;
        //   case 1:
        //     this.sortBy = "DateAdded";
        //     break;
        //   case 5:
        //     this.sortBy = "DateAdded";
        //     break;
        //   default:
        //     this.sortBy = "DateAdded";
        // }
        
         
        this.tasksService.getIssueList(this.pageNumber, this.pageSize, this.sortBy, this.sortOrder, this.loginId, this.filterAssignedId, this.filterProjectId,this.filterissueType, this.filterserverityType,this.filterbugs, this.filterDate,this.search).subscribe(response => {
          
          if (response != null) {
            if (response.status == "ok") { 
              
              this.taskList = response.data.value;
              
              //this.Data();
              $(".custom-loader").css("display", "none");
              if (response.data.value.length > 0) {
                console.log(response.data.value);
                console.log('After,',this.totalCount)
                this.totalCount = response.data.value[0].totalRecords;
                console.log(this.totalCount);
              } else {
                this.totalCount = 0;
              }
              if (this.taskList.length != 0) {
                // console.log(this.taskList.length);
                setTimeout(() => {
                  $(".dataTables_empty").css("display", "none");
                }, 200);
              }

              callback({
                recordsTotal: this.totalCount,
                recordsFiltered: this.totalCount,
                data: []
              });
            }
          }
        }, error => {
          if (error.status == 401) { }
        });
      },
      
    };
  }


  // getProjects() {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     dom: 'frtlip',
  //     pageLength: 25,
  //     order: [[4, 'desc']],
  //     processing: true,
  //     "destroy": true,
  //     "columnDefs": [
  //       { "orderable": false, "width": "3%", "targets": 0 },
  //       { "width": "20%", "targets": 1 },
  //       { "width": "38%", "orderable": false, "targets": 2 },
  //       { "width": "15%", "targets": 3 },
  //       { "width": "10%", "targets": 4 },
  //       { "width": "15%", "targets": 5 },
  //     ]
  //   };

  // this.tasksService.getIssueList(
  //   this.pageNumber,
  //   this.pageSize,
  //   this.sortBy,
  //   this.sortOrder,
  //   this.loginId,
  //   this.filterAssignedId,
  //   this.filterProjectId,
  //   this.filterissueType,
  //   this.filterserverityType,
  //   this.filterbugs,
  //   this.filterDate,
  //   this.search
  // ).subscribe(
  //   (response) => {
  //     if (response && response.status === "ok") {
  
  //       if (response.data.value.length > 0) {
  //          this.taskList = response.data.value 
       
  //       }
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }



  // getAllIssue() {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers', // Full pagination controls
  //     dom: 'frtlip', // Filter, table, pagination controls
  //     pageLength: 25, // Default rows per page
  //     order: [[4, 'desc']], // Default sorting: 4th column descending
  //     processing: true, // Show processing indicator
  //     serverSide: true, // Enable server-side processing
  //     destroy: true, // Reinitialize DataTable if needed
  //     columns: [
  //       { "data": 'issue', "orderable": false }, // ISSUE#
  //       { "data": 'title', "orderable": false }, // ISSUE TITLE
  //       { "data": 'projectName', "orderable": false }, // PROJECT
  //       { "data": 'dateAdded', "orderable": false }, // REPORT DATE
  //       { "data": 'status', "orderable": false }, // BUG STATUS
  //     ],
  //   };
  
  //   // API call to fetch issues
  //   this.tasksService.getIssueList(
  //     this.pageNumber,
  //     this.pageSize,
  //     this.sortBy,
  //     this.sortOrder,
  //     this.loginId,
  //     this.filterAssignedId,
  //     this.filterProjectId,
  //     this.filterissueType,
  //     this.filterserverityType,
  //     this.filterbugs,
  //     this.filterDate,
  //     this.search
  //   ).subscribe(
  //     (response) => {
  //       if (response && response.status === "ok") {
  //         // Store and process the response data
  //         this.taskList = response.data.value || [];
  //         this.totalCount = response.data.value.length > 0 ? response.data.value[0].totalRecords : 0;
  
  //         // Hide loader and handle empty data
  //         $(".custom-loader").css("display", "none");
  //         if (this.taskList.length === 0) {
  //           $(".dataTables_empty").css("display", "block");
  //         } else {
  //           $(".dataTables_empty").css("display", "none");
  //         }
  //       }
  //     },
  //     (error) => {
  //       // Handle errors (e.g., unauthorized)
  //       if (error.status === 401) {
  //         console.error("Unauthorized access");
  //       }
  //     }
  //   ); // <-- Properly closed the subscribe block
  // }
  
  getUser() {
    if (Constants.adminLevelUsers.length == 0) {
      this.userService.getAdminlevels().subscribe(response => {
        if (response.status == "ok") {
          Constants.adminLevelUsers = this.userList = response.data.value;
          this.selectedAssignee = this.userList[0];
          this.assignedId = this.userList[0].userId;
          for (var i = 0; i < this.userList.length; i++) {
            this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.userList = Constants.adminLevelUsers;
      this.selectedAssignee = this.userList[0];
      this.assignedId = this.userList[0].userId;
      for (var i = 0; i < this.userList.length; i++) {
        this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
      }
    }
  }

  getProject() {
    if (Constants.projectList.length == 0) {
      this.projectService.getActiveProjectList().subscribe(response => {
        if (response.status == "ok") {
          if (response.data.value.length > 0) {
            Constants.projectList = this.projectList = response.data.value;
            this.activeProjects = _.filter(this.projectList, function(o) { return o.isActive == true; });
            this.selectedProject = this.activeProjects[0];
            this.projectId = this.activeProjects[0].id;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.projectList = Constants.projectList;
      this.activeProjects = _.filter(this.projectList, function(o) { return o.isActive == true; });
            this.selectedProject = this.activeProjects[0];
            this.projectId = this.activeProjects[0].id;
      // this.selectedProject = this.projectList[0];
      // this.projectId = this.projectList[0].id;
    }
  }

  // addTaskToggle() {
  //   if (!this.boxToggle) {
  //     this.boxToggle = true;
  //     this.isTaskAdded = false;
  //   } else {
  //     this.isTaskAdded = false;
  //     this.taskId = 0;
  //     this.isShowLogSlide = false;
  //     $('.slider-log').addClass('close-b');
  //     $(".slider-log-wrapper").height(0);
  //   }
  //   this.resetTaskForm();
  // }

  closeTaskToggle() {
    $('.slider-log').addClass('close-b');
    $(".slider-log-wrapper").height(0);
    this.isShowLogSlide = false;
    this.resetTaskForm();
    this.isTaskAdded = false;
    this.boxToggle = false;
    this.taskId = 0;
  }

  resetTaskForm() {
    this.taskId = 0;
    this.title = '';
    this.description = '';
    this.selectedProject = this.activeProjects[0];
    if (this.loginUser.accessLevel != 'super_admin') {
      this.selectedItems = [];
      //this.selectedItems.push({ "id": this.loginUser.userId, "itemName": this.loginUser.firstName, "profilePhoto": this.loginUser.profilePhoto })
      this.selectedAssignee = this.loginUser;
    } else {
      // this.selectedItems.push({ "id": this.userList[0].userId, "itemName": this.userList[0].firstName, "profilePhoto": this.userList[0].profilePhoto })
      this.selectedAssignee = this.userList[0];
      this.selectedItems = [];
    }
    this.estimatedhours = 0;
    this.dueDatepicker = this.calendar.getToday();
    this.taskDate = moment().year(this.dueDatepicker.year).month(this.dueDatepicker.month - 1).date(this.dueDatepicker.day).format();
    this.sendEmail = false;
    this.isBug = false;
    this.attachDocumentName = [];
    this.taskDetail = '';
    this.selectedBugStatus = 'Pending'; 
    this.issue =0; 
    this.issueTypes= 'Bug';
    this.sevverity = 'High';
    this.isBugChange();
  }

  resetLogForm() {
    this.logComment = '';
    this.actualhours = 0;
    this.logDatepicker = this.calendar.getToday();
    this.taskDateLog = moment().year(this.logDatepicker.year).month(this.logDatepicker.month - 1).date(this.logDatepicker.day).format();
  }

  filterReset() {
    $("#filterStatusOpt").val("all");
    this.filterAssignedId = 0;
    this.filterAssignedById = 0;
    this.filterProjectId = 0;
    this.filterStatus = -1;
    this.filterBug = -1;
    this.filterDate = 0;
    this.pageNumber = 1;
    this.pageSize = 25;
    this.filterissueType='';
    this.filterbugs='';
    this.filterserverityType='';
    this.rerender();
  }

  filterApplications() {
    
    this.rerender();
  }
 

  addTask() {
    $(".btn-submit").prop('disabled', true);
    this.projectId = this.selectedProject.id;
    this.assignedId = this.selectedAssignee.userId;
    //this.taskDate = moment().year(this.dueDatepicker.year).month(this.dueDatepicker.month - 1).date(this.dueDatepicker.day).format();
  //console.log(this.taskDate);
    //this.taskStartDate = moment().year(this.startDatePicker.year).month(this.startDatePicker.month - 1).date(this.startDatePicker.day).format();
   
    let isValid = this.validate();
    if (isValid) {
      var taskObj = {
        Title: '',
        JobId: null,
        ProjectId: 0,
        Followers: "",
        AssignedId: null,
        AssignedbyId: 0,
        Description: '',
        EstimatedHours: 0,
        IsCompleted: false,
        IsBug: false,
        Status: this.selectedBugStatus, 
        Sevverity: this.sevverity,
        IssueType:this.issueTypes,
        Issue:this.issue,
        DueDate: new Date(),
        DateAdded: new Date(),
        DateUpdated: new Date(),
        
        IsSendEmail: false
      };
      this.selectedItems.forEach(element => {
        if (element.id != this.assignedId) {
          if (taskObj.Followers == "") {
            taskObj.Followers = element.id
          } else {
            taskObj.Followers = taskObj.Followers + "," + element.id
          }
        }
      });
      if ((!this.estimatedhours) || (this.estimatedhours == null)) {
        this.estimatedhours = 0;
      }
      //assign value
      taskObj.Title = this.title.trim();
      taskObj.Description = this.description.trim();
      taskObj.ProjectId = this.projectId;
      taskObj.AssignedId = this.assignedId;
      taskObj.AssignedbyId = this.loginUser.userId;
      taskObj.EstimatedHours = this.estimatedhours;
      taskObj.DueDate = new Date(this.taskDate);
     // taskObj.StartDate= new Date(this.taskStartDate);
      taskObj.DateAdded = new Date(this.taskStartDate);
      taskObj.DateUpdated = new Date();
      taskObj.IsSendEmail = this.sendEmail;
      taskObj.IsBug = this.isBug;
      if (taskObj.IsBug == false) {
        taskObj.Status = '';
        taskObj.Issue = 0;
        taskObj.IssueType = '';
        taskObj.Sevverity = '';
      } else {
        taskObj.Status = this.selectedBugStatus;
        taskObj.Issue = this.issue;
        taskObj.IssueType = this.issueTypes;
        taskObj.Sevverity = this.sevverity;
      }
  

      // if(taskObj.DueDate > taskObj.DateAdded){
      this.tasksService.addTask(taskObj).subscribe(res => {
        if (res.status == "ok") {
          this.taskId = res.data.id;
          if (this.attachDocumentName.length > 0) {
            this.uploadFiles(res.data);
          } else {
            this.noti.notification("Success", "Task Added Successfully", "success");
            this.rerender();
            this.showTaskDetails(res.data);
            this.isTaskAdded = true;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      $(".btn-submit").prop('disabled', false);
    }
  // }
  // this.noti.notification("Error", "Your Due Date Smaller Then Start Date  ", "danger");
  }

  updateTask() {
    $(".btn-submit").prop('disabled', true);
    this.assignedId = this.selectedAssignee.userId;
    this.projectId = this.selectedProject.id;
   // this.taskDate = moment().year(this.dueDatepicker.year).month(this.dueDatepicker.month - 1).date(this.dueDatepicker.day).format();
    //this.taskStartDate = moment().year(this.startDatePicker.year).month(this.startDatePicker.month - 1).date(this.startDatePicker.day).format();
    let isValid = this.validate();
    if (isValid) {
      var taskObj = {
        Id: 0,
        Title: '',
        JobId: null,
        ProjectId: 0,
        Followers: "",
        AssignedbyId: 0,
        Description: '',
        AssignedId: null,
        EstimatedHours: 0,
        IsCompleted: false,
        IsBug: false,
        Issue:this.issue,
        DueDate: new Date(),
        DateAdded: new Date(),
        DateUpdated: new Date(),
        Status: this.selectedBugStatus, 
        Sevverity: this.sevverity,
        IssueType:this.issueTypes,
        IsSendEmail: false,
      };
      this.selectedItems.forEach(element => {
        if (element.id != this.assignedId) {
          if (taskObj.Followers == "") {
            taskObj.Followers = element.id
          } else {
            taskObj.Followers = taskObj.Followers + "," + element.id
          }
        }
      });
      if ((!this.estimatedhours) || (this.estimatedhours == null)) {
        this.estimatedhours = 0;
      }
      //assign value
      taskObj.Id = this.taskId;
      taskObj.Title = this.title.trim();
      taskObj.Description = this.description.trim();//.replace(/(\r\n|\n)/g, "<br/>");;
      taskObj.ProjectId = this.projectId;
      taskObj.AssignedId = this.assignedId;
      taskObj.AssignedbyId = this.loginUser.userId;
      taskObj.EstimatedHours = this.estimatedhours;
      taskObj.DueDate =new Date( this.taskDate);
    
      taskObj.DateAdded =new Date( this.taskStartDate);
      taskObj.DateUpdated = new Date();
      taskObj.IsSendEmail = this.sendEmail;
      taskObj.IsBug = this.isBug;
      if (taskObj.IsBug == false) {
        taskObj.Status = '';
        taskObj.Issue = 0;
        taskObj.IssueType = '';
        taskObj.Sevverity = '';
      } else {
        taskObj.Status = this.selectedBugStatus;
        taskObj.Issue = this.issue;
        taskObj.IssueType = this.issueTypes;
        taskObj.Sevverity = this.sevverity;
      }
    

  // if(taskObj.DueDate > taskObj.DateAdded){
      this.tasksService.updateTask(taskObj).subscribe(res => {
        if (res.status == "ok") {
          if (this.attachDocumentName.length > 0) {
            this.updateFiles(res.data);
          } else {
            this.showTaskDetails(res.data);
            this.noti.notification("Success", "Task Updated Successfully", "success");
            this.rerender();
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      $(".btn-submit").prop('disabled', false);
    }
  // }
  // this.noti.notification("Error", "Your Due Date Smaller Then Start Date  ", "danger");
 
  }

  getTaskWithLogs(id) {
    this.selectedItems = [];
    this.isTaskAdded = true;
    this.isShowLogSlide = false;
    $('.slider-log').addClass('close-b');
    $(".slider-log-wrapper").height(0);
    this.taskId = id;
    this.boxToggle = true;
    this.attachDocumentName = [];
    this.logDatepicker = this.calendar.getToday();
    this.taskDetail = "";
    this.tasksService.getTask(id).subscribe(res => {
      if (res.status == "ok") {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.taskDetail = res.data;
        this.title = this.taskDetail.title;
        this.description = this.taskDetail.description;
        this.estimatedhours = this.taskDetail.estimatedHours;
        let yearT: number = moment(this.taskDetail.dueDate).get('year');
        let monthT: number = moment(this.taskDetail.dueDate).get('month') + 1;
        let dayT: number = moment(this.taskDetail.dueDate).get('date');
        this.dueDatepicker = { year: yearT, month: monthT, day: dayT };

        if (this.taskDetail.followers != "" && this.taskDetail.followers != null) {
          this.followers = this.taskDetail.followers.split(',');
        } else {
          this.followers = [];
        }

        for (var i = 0; i < this.userList.length; i++) {
          if (this.userList[i].userId == this.taskDetail.assignedId) {
            this.selectedAssignee = this.userList[i];
          }
          for (var f = 0; f < this.followers.length; f++) {
            if (this.followers[f] == this.userList[i].userId) {
              this.selectedItems.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto })
            }
          }
        }

        for (var i = 0; i < this.activeProjects.length; i++) {
          if (this.activeProjects[i].id == this.taskDetail.projectId) {
            this.selectedProject = this.activeProjects[i];
            break;
          }
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
    // }
  }

  showTasksDetails(data) {

    this.selectedItems = [];
    this.isTaskAdded = true;
    this.isShowLogSlide = false;
    // this.isBug = false;
    this.sendEmail = false;
    $('.slider-log').addClass('close-b');
    $(".slider-log-wrapper").height(0);
    this.boxToggle = true;
    this.attachDocumentName = [];
    this.logDatepicker = this.calendar.getToday();
    this.taskDetail = "";
    this.isApproved = data.isapproved;
    this.approvedBy = data.approvedBy;
    this.taskId = data.id;
    this.taskDetail = data;
    this.title = data.title;
    this.description = data.description;
    this.estimatedhours = data.estimatedHours;
    this.isBug = data.isBug;
    this.selectedBugStatus = data.status;
    this.issueTypes=data.issueType;
    this.sevverity=data.sevverity;
    this.issue=data.issue;
    if (data.assignedbyId == data.assignedId) {
      this.assignedBy = "Self";
    } else {
      this.assignedBy = data.assignedbyName;
    }
    
    let yearT: number = moment(data.dueDate).get('year');
    let monthT: number = moment(data.dueDate).get('month') + 1;
    let dayT: number = moment(data.dueDate).get('date');
    // this.dueDatepicker = { year: yearT, month: monthT, day: dayT };
    this.dueDate ={ year: yearT, month: monthT, day: dayT };
    let yearT1: number = moment(data.dateAdded).get('year');
    let monthT1: number = moment(data.dateAdded).get('month') + 1;
    let dayT1: number = moment(data.dateAdded).get('date');
    this.addDate={ year: yearT1, month: monthT1, day: dayT1 };
    $("html, body").animate({ scrollTop: 0 }, "slow");


    if (data.followers != "" && data.followers != null) {
      this.followers = data.followers.split(',');
    } else {
      this.followers = [];
    }

    for (var i = 0; i < this.userList.length; i++) {
      if (this.userList[i].userId == data.assignedId) {
        this.selectedAssignee = this.userList[i];
      }
      for (var f = 0; f < this.followers.length; f++) {
        if (this.followers[f] == this.userList[i].userId) {
          this.selectedItems.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto })
        }
      }
    }

    for (var i = 0; i < this.activeProjects.length; i++) {
      if (this.activeProjects[i].id == data.projectId) {
        this.selectedProject = this.activeProjects[i];
        break;
      }
    }

    //Get task logs
    this.isTaskLogs = true;
    this.tasksService.getTaskLogs(data.id).subscribe(response => {
      if (response.status == "ok") {
        this.isTaskLogs = false;
        this.taskDetail.logsItems = response.data.value;
      }
    }, error => {
      this.isTaskLogs = false;
      if (error.status == 401) { }
    });

    //Get task files
    this.formData.delete('files');
    this.attachDocumentName = [];
    this.tasksService.getTaskAttachments(data.id).subscribe(response => {
      if (response.status == "ok") {
        this.taskDetail.files = response.data.value;
      }
    }, error => {
      if (error.status == 401) { }
    });

    $(".btn-submit").prop('disabled', false);
    setTimeout(() => {
      this.isBugChange();
    }, 480);
  }
  showTaskDetails(data) {

    this.selectedItems = [];
    this.isTaskAdded = true;
    this.isShowLogSlide = false;
    // this.isBug = false;
    this.sendEmail = false;
    $('.slider-log').addClass('close-b');
    $(".slider-log-wrapper").height(0);
    this.boxToggle = true;
    this.attachDocumentName = [];
    this.logDatepicker = this.calendar.getToday();
    this.taskDetail = "";
    this.isApproved = data.isApproved;
    this.approvedBy = data.approvedBY;
    this.taskId = data.id;
    this.taskDetail = data;
    this.title = data.title;
    this.description = data.description;
    this.estimatedhours = data.estimatedHours;
    this.isBug = data.isBug;
    this.selectedBugStatus = data.status;
    this.issueTypes=data.issueType;
    this.sevverity=data.sevverity;
    this.issue=data.issue;
    if (data.assignedbyId == data.assignedId) {
      this.assignedBy = "Self";
    } else {
      this.assignedBy = data.assignedbyName;
    }
    
    let yearT: number = moment(data.dueDate).get('year');
    let monthT: number = moment(data.dueDate).get('month') + 1;
    let dayT: number = moment(data.dueDate).get('date');
    // this.dueDatepicker = { year: yearT, month: monthT, day: dayT };
    this.dueDate ={ year: yearT, month: monthT, day: dayT };
    let yearT1: number = moment(data.dateAdded).get('year');
    let monthT1: number = moment(data.dateAdded).get('month') + 1;
    let dayT1: number = moment(data.dateAdded).get('date');
    this.addDate={ year: yearT1, month: monthT1, day: dayT1 };
    $("html, body").animate({ scrollTop: 0 }, "slow");


    if (data.followers != "" && data.followers != null) {
      this.followers = data.followers.split(',');
    } else {
      this.followers = [];
    }

    for (var i = 0; i < this.userList.length; i++) {
      if (this.userList[i].userId == data.assignedId) {
        this.selectedAssignee = this.userList[i];
      }
      for (var f = 0; f < this.followers.length; f++) {
        if (this.followers[f] == this.userList[i].userId) {
          this.selectedItems.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto })
        }
      }
    }

    for (var i = 0; i < this.activeProjects.length; i++) {
      if (this.activeProjects[i].id == data.projectId) {
        this.selectedProject = this.activeProjects[i];
        break;
      }
    }

    //Get task logs
    this.isTaskLogs = true;
    this.tasksService.getTaskLogs(data.id).subscribe(response => {
      if (response.status == "ok") {
        this.isTaskLogs = false;
        this.taskDetail.logsItems = response.data.value;
      }
    }, error => {
      this.isTaskLogs = false;
      if (error.status == 401) { }
    });

    //Get task files
    this.formData.delete('files');
    this.attachDocumentName = [];
    this.tasksService.getTaskAttachments(data.id).subscribe(response => {
      if (response.status == "ok") {
        this.taskDetail.files = response.data.value;
      }
    }, error => {
      if (error.status == 401) { }
    });

    $(".btn-submit").prop('disabled', false);
    setTimeout(() => {
      this.isBugChange();
    }, 480);
  }

  deleteComfirm() {
    $("#deleteModal").modal("show");
  }

  deleteTask() {
    $(".btn-submit").prop('disabled', true);
    this.tasksService.deleteTask(this.taskId).subscribe(res => {
      if (res.status == "ok") {
        $(".btn-submit").prop('disabled', false);
        this.noti.notification("Success", "Task Deleted Successfully", "success");
        this.closeTaskToggle();
        this.rerender();
      } else {
        $(".btn-submit").prop('disabled', false);
        this.noti.notification("Error", "Task not Deleted", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteModal").modal("hide");
  }

  onDateSelect(event) {
    this.taskDate = moment().year(event.year).month(event.month - 1).date(event.day).format();
    
  }

  onStartDateSelect(event) {
    this.taskStartDate = moment().year(event.year).month(event.month - 1).date(event.day).format();
  
  }

  addTaskLog() {
    $(".btn-submit").prop('disabled', true);

    // Format the log date using the date picker values
    this.taskDateLog = moment().year(this.logDatepicker.year)
                              .month(this.logDatepicker.month - 1)
                              .date(this.logDatepicker.day)
                              .format();

    // Create log object
    let logObj = {
        TaskId: this.taskId,
        ProjectId: this.taskDetail.projectId,
        UserId: this.loginUser.userId,
        Description: this.logComment.trim(),
        ActualHours: this.actualhours,
        DateAdded: new Date(this.taskDateLog),
        DateUpdated: new Date()
    };

   // Check if any field is null or invalid
if (
  logObj.TaskId === undefined || 
  logObj.ProjectId === undefined || 
  logObj.UserId === undefined || 
  logObj.Description === '' || // Check if the description is empty
  logObj.ActualHours === undefined || 
  logObj.DateAdded === undefined
) {
  // Send error notification if any field is null or invalid
  this.noti.notification("Error", "Some fields are missing or invalid", "danger");

  // Reset the form
  this.resetLogForm();

  // Enable the submit button again
  $(".btn-submit").prop('disabled', false);

  // Exit the function
  return;
}


    // Proceed with the form submission if all fields are valid
    this.tasksService.addTaskLog(logObj).subscribe(res => {
        if (res.status === "ok") {
            // Success notification
            this.noti.notification("Success", "Task Log Added Successfully", "success");

            // Push the new log to the task logs and sort them by dateAdded
            this.taskDetail.logsItems.push(res.data);
            this.taskDetail.logsItems.sort(function (a, b) {
                return (a.dateAdded > b.dateAdded) ? -1 : (a.dateAdded < b.dateAdded) ? 1 : 0;
            });

            // Reset the form after success
            this.resetLogForm();
        }
        // Enable the submit button after the process
        $(".btn-submit").prop('disabled', false);
    }, error => {
        if (error.status === 401) {
            this.noti.notification("Error", "Unauthorized", "error");
        }
        // Enable the submit button after an error
        $(".btn-submit").prop('disabled', false);
    });
}



  
  // addTaskLog() {
  //   $(".btn-submit").prop('disabled', true);
  //   this.taskDateLog = moment().year(this.logDatepicker.year).month(this.logDatepicker.month - 1).date(this.logDatepicker.day).format();
  //   let isValid = this.validateLog();
  //   if (isValid) {
  //     var logObj = {
  //       TaskId: 0,
  //       ProjectId: 0,
  //       UserId: 0,
  //       Description: '',
  //       ActualHours: 0,
  //       DateAdded: new Date(),
  //       DateUpdated: new Date()
  //     };
  //     // if ((!this.actualhours) || (this.actualhours == null)) {
  //     //    this.actualhours = 0;
  //     // }
  //     //assign value
  //     logObj.TaskId = this.taskId;
  //     logObj.ProjectId = this.taskDetail.projectId;
  //     logObj.UserId = this.loginUser.userId;
  //     logObj.Description = this.logComment.trim();//.replace(/(\r\n|\n)/g, "<br/>");
  //     logObj.ActualHours = this.actualhours;
  //     logObj.DateAdded = new Date(this.taskDateLog);
  //     logObj.DateUpdated = new Date();

  //     this.tasksService.addTaskLog(logObj).subscribe(res => {
  //       if (res.status == "ok") {
  //         this.noti.notification("Success", "Task Added Successfully", "success");
  //         this.taskDetail.logsItems.push(res.data);// = res.data;
  //         this.taskDetail.logsItems.sort(function (a, b) {
  //           if (a.dateAdded > b.dateAdded) { return -1; }
  //           if (a.dateAdded < b.dateAdded) { return 1; }
  //           return 0;
  //         });
  //         //this.getTaskWithLogs(this.taskId)
  //         this.resetLogForm();
  //         $(".btn-submit").prop('disabled', false);
  //       }
  //     }, error => {
  //       if (error.status == 401) { }
  //     });
  //   } else {
  //     this.resetLogForm();
  //     $(".btn-submit").prop('disabled', false);
  //   }
  // }

  editTaskLog(id) {
    this.taskLogId = id;
    this.isTasklogEdit = true;
    this.tasksService.getTaskLog(this.taskLogId).subscribe(res => {
      if (res.status == "ok") {
        this.logDetail = res.data;
        this.logComment = this.logDetail.description;//.replace(/(<br\/>)/g, "\n");;
        this.actualhours = this.logDetail.actualHours;
        let yearT: number = moment(this.logDetail.dateAdded).get('year');
        let monthT: number = moment(this.logDetail.dateAdded).get('month') + 1;
        let dayT: number = moment(this.logDetail.dateAdded).get('date');
        this.logDatepicker = { year: yearT, month: monthT, day: dayT };
        if (!this.isShowLogSlide) {
          this.showLogSlide();
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  submitEditTaskLog() {
    $(".btn-submit").prop('disabled', true);
    this.taskDateLog = moment().year(this.logDatepicker.year).month(this.logDatepicker.month - 1).date(this.logDatepicker.day).format();
    // if ((!this.actualhours) || (this.actualhours == null)) {
    //   this.actualhours = 0;
    // }
    //assign value
    let isValid = this.validateLog();
    if (isValid) {
      this.logDetail.description = this.logComment.trim();//.replace(/(\r\n|\n)/g, "<br/>");
      this.logDetail.actualHours = this.actualhours;
      this.logDetail.dateAdded = new Date(this.taskDateLog);
      this.logDetail.UserId = this.loginUser.userId;
      this.logDetail.projectId = this.taskDetail.projectId;
      this.tasksService.updateTaskLog(this.logDetail).subscribe(res => {
        if (res.status == "ok") {
          this.noti.notification("Success", "Comment Updated Successfully", "success");
          for (var i = 0; i < this.taskDetail.logsItems.length; i++) {
            if (this.taskDetail.logsItems[i].id == res.data.id) {
              this.taskDetail.logsItems[i] = res.data;
              break;
            }
          }
          // this.taskDetail.logsItems.sort(function (a, b) {
          //   if (a.dateAdded > b.dateAdded) { return -1; }
          //   if (a.dateAdded < b.dateAdded) { return 1; }
          //   return 0;
          // });
          //this.getTaskWithLogs(this.logDetail.taskId)
          this.cancelTaskLog();
          $(".btn-submit").prop('disabled', false);
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      $(".btn-submit").prop('disabled', false);
    }
  }

  deleteTaskLog(id) {
    this.tasksService.deleteTaskLog(id).subscribe(res => {
      if (res.status == "ok") {
        this.noti.notification("Success", "Comment Deleted Successfully", "success");
        this.cancelTaskLog();
        for (var i = 0; i < this.taskDetail.logsItems.length; i++) {
          if (this.taskDetail.logsItems[i].id == res.data.id) {
            this.taskDetail.logsItems.splice(i, 1);
            break;
          }
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  cancelTaskLog() {
    this.taskLogId = 0;
    this.isTasklogEdit = false;
    this.logComment = '';
    this.actualhours = 0;
    this.logDatepicker = this.calendar.getToday();
    this.isShowLogSlide = false;
    $('.slider-log').addClass('close-b');
    $(".slider-log-wrapper").height(0);
  }

  onDateSelectLog(event) {
    this.taskDateLog = moment().year(event.year).month(event.month - 1).date(event.day).format();
  }

  validate() {
    if (this.title.length < 4) {
      this.noti.notification("Error", "Please select title atleast 5 characters", "danger");
      return false;
    }
    if (!(!!this.projectId)) {
      this.noti.notification("Error", "Please select project", "danger");
      return false;
    }
    // if (!(!!this.assignedId)) {
    //   this.noti.notification("Error", "Please select assiged to", "danger");
    //   return false;
    // }
    return true;
  }

  validateLog() {
    if (this.logComment.length < 3) {
      this.noti.notification("Error", "Please write comment atleast 3 characters", "danger");
      return false;
    }
    if ((!this.actualhours) || (this.actualhours == null)) {
      this.noti.notification("Error", "Please enter actual hours.", "danger");
      return false;
    }
    return true;
  }

  showLogSlide() {
    this.isShowLogSlide = true;
    $('.slider-log').toggleClass('close-b');
    var newHeight = $("#comment-add-form").outerHeight();
    $(".slider-log-wrapper").height(newHeight);
  }

  rerender(): void { 
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first 
      dtInstance.destroy();
      // Call the dtTrigger to rerender again 
      this.dtTrigger.next();
    });
  }

  selectPeople(data) {
    this.selectedAssignee = data;
  }

  selectProject(data) {
    this.selectedProject = data;
  }

  markCompleteTask(event: Event, id) {
    event.stopPropagation();
    $(".btn-submit").prop('disabled', true);
    this.projectId = this.selectedProject.id;//result.projectControl;
    this.assignedId = this.selectedAssignee.userId;//result.userControl;
    //this.taskDate = moment().year(this.dueDatepicker.year).month(this.dueDatepicker.month - 1).date(this.dueDatepicker.day).format();

    var taskObj = {
      Id: 0,
      Title: '',
      Description: '',
      ProjectId: 0,
      AssignedId: 0,
      AssignedbyId: 0,
      EstimatedHours: 0,
      JobId: null,
      IsCompleted: false,
      DueDate: new Date(),
      DateAdded: new Date(),
      DateUpdated: new Date(),
      IsSendEmail: false
    };
    if ((!this.estimatedhours) || (this.estimatedhours == null)) {
      this.estimatedhours = 0;
    }
    //assign value
    if (!(!!id)) {
      taskObj.Id = this.taskId;
    } else {
      taskObj.Id = id;
    }

    taskObj.Title = this.title;
    taskObj.Description = this.description;
    taskObj.ProjectId = this.projectId;
    // taskObj.AssignedId = this.assignedId;
    taskObj.AssignedbyId = this.loginUser.userId;
    taskObj.EstimatedHours = this.estimatedhours;
    taskObj.DueDate = new Date(this.taskDate);
    taskObj.DateAdded = new Date();
    taskObj.DateUpdated = new Date();
    taskObj.IsCompleted = false;


    this.tasksService.updateMarkTask(taskObj).subscribe(res => {
      if (res.status == "ok") {
        this.noti.notification("Success", "Task Updated Successfully", "success");
        $(".btn-submit").prop('disabled', false);
        if (this.boxToggle) {
          this.taskDetail.isCompleted = res.data.isCompleted;
        }
        for (var i = 0; i <= this.taskList.length; i++) {
          if (this.taskList[i].id == taskObj.Id) {
            this.taskList[i].isCompleted = res.data.isCompleted;
            break;
          }
        }
        // this.rerender();
      } else {
        this.noti.notification("Error", "Please enter actual hours.", "danger");
        $(".btn-submit").prop('disabled', false);
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  addFiles(event) {
    if (this.attachDocumentName.length < 5) {
      this.files = event.target.files;
      for (let i = 0; i < this.files.length; i++) {
        this.attachDocumentName.push({ 'name': this.files[i].name });
        this.formData.append('files', this.files[i], this.files[i].name);
      }
    } else {
      this.noti.notification("Error", "You Already Five Files Selected", "danger");
    }
  }

  deleteDocument(data) {
    var values = this.formData.getAll("files");
    values.splice(data, 1);
    this.attachDocumentName.splice(data, 1);
    this.formData.delete('files');
    values.filter(name => name !== 'Bob').forEach(name => this.formData.append('files', name));
  }

  uploadFiles(data) {
    let url = this.URL + this.taskId;
    this.http.post(url, this.formData)
      .subscribe(respo => {
        this.showTaskDetails(data);
        // this.getTaskFiles(data.id);
        this.rerender();
        this.isTaskAdded = true;
        this.noti.notification("Success", "Task Added Successfully", "success");
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }

  updateFiles(data) {
    let url = this.URL + this.taskId;
    this.http.post(url, this.formData)
      .subscribe(respo => {
        // this.getTaskFiles(data.id);
        this.showTaskDetails(data);
        this.rerender();
        this.noti.notification("Success", "Task Updated Successfully", "success");
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }

  // getTaskFiles(id) {
  //   $(".btn-submit").prop('disabled', false);
  //   this.formData.delete('files');
  //   this.attachDocumentName = [];
  //   this.tasksService.getTaskAttachments(id).subscribe(response => {
  //     if (response.status == "ok") {
  //       this.taskDetail.files = response.data.value;
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }

  confirmDelete(data) {
    this.taskFileId = data;
    $("#deleteFileModal").modal("show");
  }

  deleteUserFile() {
    this.tasksService.deleteTaskFile(this.taskFileId).subscribe(response => {
      if (response.status == "ok") {
        for (var i = 0; i < this.taskDetail.files.length; i++) {
          if (this.taskDetail.files[i].id == this.taskFileId) {
            this.taskDetail.files.splice(i, 1);
            break;
          }
        }
        this.noti.notification("Success", "File Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This File", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteFileModal").modal("hide");
  }

  Data(){
    this.tasksService.getTaskList(this.pageNumber, this.pageSizeX, this.sortBy, this.sortOrder, this.loginId, this.filterAssignedId, this.filterAssignedById, this.filterProjectId, this.filterStatus, this.filterBug, this.filterDate).subscribe(response => {
          
      if (response != null) {
        if (response.status == "ok") { 
          
          this.taskListX = response.data.value;
       
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  generateExcelFile() {
    // Set the page size to a large number to ensure all data is fetched (you can set this to the actual total count if known)
    const pageSize = this.totalCount || 10000;
  
    // Call your task list service with a large page size to get all data
    this.tasksService.getTaskList(1, pageSize, this.sortBy, this.sortOrder, this.loginId, this.filterAssignedId, this.filterAssignedById, this.filterProjectId, this.filterStatus, this.filterBug, this.filterDate)
      .subscribe(response => {
        if (response && response.status === 'ok' && response.data && response.data.value.length > 0) {
          const allTasks = response.data.value;
  
          // Map the data to the format you want to export
          const worksheetData = allTasks.map(task => ({
            Project: task.projectName || '',
            Developer: task.assignedName || '',
            'Estimated Hours': task.estimatedHours || '',
            'Due Date': task.dueDate ? new Date(task.dueDate).toLocaleDateString() : ''
          }));
  
          // Convert the data to an Excel worksheet
          const worksheet = XLSX.utils.json_to_sheet(worksheetData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');
  
          // Generate the Excel file
          const excelBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const fileName = `TaskList.xlsx`.replace(/[/\\?%*:|"<>]/g, '_');
          saveAs(new Blob([excelBlob], { type: 'application/octet-stream' }), fileName);
        } else {
          console.error('No data available to export');
        }
      }, error => {
        console.error('Failed to fetch tasks for export', error);
      });
  }
}
