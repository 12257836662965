import { Component, OnInit } from '@angular/core';
import { TaskService } from 'src/services/task.service';
import { UserService } from 'src/services/user.service';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { NotificationService } from 'src/services/notification.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/services/dashboard.service';
import { Constants } from 'src/services/constants.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {

public selectedUser;
  public itemList = [];
  public selectedAssignee: any = "";
  public assignedId: number = 0;
  beAssigned: any[] = [];
  public taskDate: any;
  public taskStartDate:any;
  public dueDate:any;
  public addDate :any;
  public today1: any;
  public filterAssignedId: number = 0;



  public startDate = moment().format('MM/DD/YYYY');
  public endDate = moment().format('MM/DD/YYYY');
  public selectDate: any;
  public userTasks: any = [];
  public userList: any = [];
  public taskHtml = "";
  public projectHtml = "";
  public hours = 0;
 
  public estHours = 0;


  
  constructor(
    private appService: AppService,
    private taskService: TaskService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private noti: NotificationService,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "New Report"
    });
   
    this.today1 = moment().format('YYYY-MM-DD');
    //this.changeDate('yrs-day');
  }

  // onUserSelect(userId: number) {
  //   // Find the selected user
  //   this.selectedUser = this.userList.find(user => user.userId === Number(userId));

  //   if (this.selectedUser) {
  //     // Add to beAssigned list if not already added
  //     if (!this.beAssigned.some(user => user.userId === this.selectedUser.userId)) {
  //       this.beAssigned.push(this.selectedUser);
  //       console.log(`User ${this.selectedUser.firstName} assigned.`);
  //     } else {
  //       console.log(`User ${this.selectedUser.firstName} is already assigned.`);
  //     }
  //   }
  // }

  
onDateSelect(event) {
  this.taskDate = moment().year(event.year).month(event.month - 1).date(event.day).format('MM/DD/YYYY');

  console.log(this.taskDate);

}

onStartDateSelect(event) {
  this.taskStartDate =  moment().year(event.year).month(event.month - 1).date(event.day).format('MM/DD/YYYY');

  console.log(this.taskStartDate);
}

onActionClick() {
  this.taskHtml = "";
  this.userList = [];
  $("#Export").css("display", "none");
  if (!this.taskDate) { // This checks for undefined, null, or 0
    this.taskDate = null;
}
if (!this.taskStartDate) { // This checks for undefined, null, or 0
    this.taskStartDate = null;
}
if (!this.selectedUser){
  this.noti.notification("Error", "Select Employee ", "danger");
}
  if(this.taskDate < this.taskStartDate){
  this.noti.notification("Error", "Your start Date Smaller Then End Date  ", "danger");
  return 
  }
  // Call the service to get the filtered report by the user
  this.dashboardService.getFilterReportByUser(this.taskDate, this.taskStartDate, this.selectedUser.userId).subscribe(res => {
    if (res.status === "ok") {
      let data = res.data;
      this.userTasks = data;
      console.log(this.userTasks);

      // Check if data exists
      if (data.length === 0) {
        document.getElementById("no-data-found").innerHTML = "No Records Found.";
        document.getElementById("daily-task-list").innerHTML = "";         
        $(".custom-loader").css("display", "none");
        $(".loader-response").css("display", "block");
      } else {
        // Start creating the task table
        this.taskHtml += "<table class='table table-bordered'><tr style='background-color:#d7d7d7;'><td style='width: 250px;'>Name</td><td style='width: 230px;'>Project Name</td><td style='width: 70px;'>Est</td><td style='width: 70px;'>Act</td><td>Description</td></tr>";

        // Loop through user tasks and add to table for the selected user
        for (let t = 0; t < this.userTasks.length; t++) {
          // Check if the task belongs to the selected user
          if (this.userTasks[t].userId === this.selectedUser.userId) {
            // Always include the user's name in the Name column
            this.taskHtml += "<tr>";
            this.taskHtml += "<td style='width: 240px;'>" + this.selectedUser.firstName + "</td>";
            this.taskHtml += "<td style='width: 230px;'>" + 
            "<img style='height: 30px; width: 30px;' src='" + (this.userTasks[t].projectLogo ? this.userTasks[t].projectLogo : '/assets/img/default-project.png') + 
            "' onError=\"this.onerror=null;this.src='/assets/img/default-project.png'\" class='user-icon' /> " +
            this.userTasks[t].projectName + 
            "</td>";
            this.taskHtml += "<td style='width: 70px;'>" + this.userTasks[t].estimatedHours + "</td>";
            this.taskHtml += "<td style='width: 70px;'>" + this.userTasks[t].hours + "</td>";
            this.taskHtml += "<td>" + 
            "<b>" + this.userTasks[t].taskTitle + "</b><br>" +
            this.userTasks[t].description + 
            "</td>";
            this.taskHtml += "</tr>";

            // Accumulate hours and estimated hours
            this.hours += parseFloat(this.userTasks[t].hours);
            this.estHours += parseFloat(this.userTasks[t].estimatedHours);
          }
        }

        // Add total hours row if any tasks exist for the user
      // Add total hours row if any tasks exist for the user
if (this.hours > 0) {
  this.taskHtml += "<tr><th></th> <th class='border-left-0 border-bottom-0'>Total Hours</th><th class='border-bottom-0'>" + this.estHours + "</th><th colspan='2' class='border-bottom-0 border-right-0'>" + this.hours + "</th></tr>";
} else {
  this.taskHtml += "<tr><th class='border-0 text-danger'>No Entry</th></tr>";
}
console.log(this.hours+" hours");

        this.taskHtml += "</table>";
        
        // Reset hours and estimated hours for next rendering
        this.hours = 0;
        this.estHours = 0;

        // Render the final task HTML
        document.getElementById("daily-task-list").innerHTML = this.taskHtml;
        document.getElementById("no-data-found").innerHTML = "";
        $(".custom-loader").css("display", "none");
        $(".loader-response").css("display", "block");
        console.log("Functionn End "+ this.filterAssignedId);
      
      }
      this.getUserAndSelect(this.filterAssignedId);
      $("#Export").css("display", "block");

    }
  }, error => {
    if (error.status === 401) {
      // Handle unauthorized error
    }
  });
}

generateExcelFile() {
  // Prepare the data to export
  let exportData = [];
  
  // Add headers to the export data
  exportData.push(["Name", "Project Name", "Estimated Hours", "Actual Hours", "Task Title", "Description"]);
  
  // Variables to hold the sum of estimatedHours and actual hours
  let totalEstimatedHours = 0;
  let totalActualHours = 0;
  
  // Loop through the userTasks to prepare the data for Excel
  for (let task of this.userTasks) {
    if (task.userId === this.selectedUser.userId) {
      let row = [
        this.selectedUser.firstName,      // Name
        task.projectName,                 // Project Name
        task.estimatedHours,              // Estimated Hours
        task.hours,                       // Actual Hours
        task.taskTitle,                   // Task Title
        task.description                  // Description in a separate column
      ];
      exportData.push(row);

      // Accumulate the total estimated hours and actual hours
      totalEstimatedHours += parseFloat(task.estimatedHours);
      totalActualHours += parseFloat(task.hours);
    }
  }

  // Add a row for the total sum of estimated and actual hours
  exportData.push([
    "",                      
    "Total",                  
    totalEstimatedHours,       
    totalActualHours,         
    "",                       
    ""                       
  ]);

  // Create a new workbook and worksheet
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'User Tasks');

  let formattedTaskDate = this.taskDate ? new Date(this.taskDate).toLocaleDateString().replace(/\//g, '-') : new Date().toLocaleDateString().replace(/\//g, '-');
  let formattedTaskStartDate = this.taskStartDate ? new Date(this.taskStartDate).toLocaleDateString().replace(/\//g, '-') : new Date().toLocaleDateString().replace(/\//g, '-');
  
  XLSX.writeFile(workbook, `${this.selectedUser.firstName}(${formattedTaskStartDate}-${formattedTaskDate}).xlsx`);
  
  
}


getUserAndSelect(userId: number) {
  // Check if admin-level users list is empty and fetch if necessary
  if (Constants.adminLevelUsers.length === 0) {
    this.userService.getAdminlevel().subscribe(response => {
      if (response.status === "ok") {
        // Populate Constants and userList with fetched data
        Constants.adminLevelUsers = this.userList = response.data.value;
        this.selectedAssignee = this.userList[0];
        this.assignedId = this.userList[0].userId;

        // Populate itemList for other purposes if needed
        for (var i = 0; i < this.userList.length; i++) {
          this.itemList.push({
            "id": this.userList[i].userId,
            "itemName": this.userList[i].firstName,
            "profilePhoto": this.userList[i].profilePhoto
          });
        }

        // Handle user selection if a valid userId is passed
        this.selectUserById(userId);
      }
    }, error => {
      if (error.status === 401) {
        console.error("Unauthorized error.");
      }
    });
  } else {
    // If data is already available, use the cached data
    this.userList = Constants.adminLevelUsers;
    this.selectedAssignee = this.userList[0];
    this.assignedId = this.userList[0].userId;

    // Populate itemList for other purposes if needed
    for (var i = 0; i < this.userList.length; i++) {
      this.itemList.push({
        "id": this.userList[i].userId,
        "itemName": this.userList[i].firstName,
        "profilePhoto": this.userList[i].profilePhoto
      });
    }

    // Handle user selection if a valid userId is passed
    this.selectUserById(userId);
  }
}

// New helper method to handle user selection
selectUserById(userId: number) {
  // Find the selected user based on userId
  this.selectedUser = this.userList.find(user => user.userId === Number(userId));

  if (this.selectedUser) {
    // Add to beAssigned list if not already added
    if (!this.beAssigned.some(user => user.userId === this.selectedUser.userId)) {
      this.beAssigned.push(this.selectedUser);
      console.log(`User ${this.selectedUser.firstName} assigned.`);
    } else {
      console.log(`User ${this.selectedUser.firstName} is already assigned.`);
    }
  }
}

  



  // getUser() {
  //   if (Constants.adminLevelUsers.length == 0) {
  //     this.userService.getAdminlevel().subscribe(response => {
  //       if (response.status == "ok") {
  //         Constants.adminLevelUsers = this.userList = response.data.value;
  //         this.selectedAssignee = this.userList[0];
  //         this.assignedId = this.userList[0].userId;
  //         for (var i = 0; i < this.userList.length; i++) {
  //           this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
  //         }
  //       }
  //      console.log("this.is"+this.filterAssignedId)
  //     }, error => {
  //       if (error.status == 401) { }
  //     });
  //   } else {
  //     this.userList = Constants.adminLevelUsers;
  //     this.selectedAssignee = this.userList[0];
  //     this.assignedId = this.userList[0].userId;
  //     for (var i = 0; i < this.userList.length; i++) {
  //       this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
  //     }
  //   }
  // }
}
