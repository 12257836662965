import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FromNowPipe } from 'src/pipes/from-now.pipe'
import { LocalDatePipe } from 'src/pipes/local-date.pipe'
import { yearPipe } from 'src/pipes/year.pipe'
import { NumberFormaterPipe } from 'src/pipes/number-formater.pipe';
import { KeyValuePipe } from '../pipes/key-value.pipe';
import { StrReplacePipe } from '../pipes/str-replace.pipe';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { StarRatingModule } from 'angular-star-rating';
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; 
import { CookieService } from 'ngx-cookie-service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
// angular2-multiselect-dropdown
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { QuillModule } from 'ngx-quill'
//service
import { AppService } from './app.service';
import { NotificationService } from 'src/services/notification.service';
import { UserService } from 'src/services/user.service';
import { ProjectService } from 'src/services/projects.service';
import { TaskService } from 'src/services/task.service';
import { TasksService } from 'src/services/tasks.service';
import { AuthenticateService } from 'src/services/authenticate.service';
import { DashboardService } from 'src/services/dashboard.service';
import { AssetsService } from 'src/services/assets.service';
import { RatingService } from 'src/services/rating.service';
import { JobApplicationsService } from 'src/services/jobApplications.service';
import { CodeCommitService } from 'src/services/codecommit.service';
import { BaltoroService } from 'src/services/baltoro.service';
import { NotesService } from 'src/services/notes.service';
import { AppBuildService } from 'src/services/appBuild.service';
import { WebsiteService } from 'src/services/websites.service';
import { ValiService } from 'src/services/vali.service';
import { AutomatedTestingService } from "../services/automated-testing.service";

import { AppComponent } from './app.component';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainpanelComponent } from './mainpanel/mainpanel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';

//Project
import { ProjectListComponent } from './project/list/list.component';
import { ProjectViewComponent } from './project/view/view.component';
import { ProjectAddComponent } from './project/add/add.component';
import { ProjectEditComponent } from './project/edit/edit.component';

//User
import { UserListComponent } from './user/list/list.component';
import { UserViewComponent } from './user/view/view.component';
import { UserAddComponent } from './user/add/add.component';
import { UserEditComponent } from './user/edit/edit.component';

//Tasks
// import { TaskListComponent } from './task/list/list.component';
// import { TaskViewComponent } from './task/view/view.component';
// import { TaskAddComponent } from './task/add/add.component';
// import { TaskEditComponent } from './task/edit/edit.component';
//login
import { LoginComponent } from './login/login.component';
//Report/
import { WeeklyComponent } from './report/weekly/weekly.component';
import { MonthlyComponent } from './report/monthly/monthly.component';
import { DailyComponent } from './report/daily/daily.component';
import { StatsComponent } from './report/stats/stats.component';
//Baltoro
import { ViewLogComponent } from './baltoro/view-log/view-log.component';
import { BaltoroDashboardComponent } from './baltoro/baltoro-dashboard/baltoro-dashboard.component';
//Histogram
import { HistogramComponent } from './histogram/histogram.component';
//Assets
import { AssetManagementSystemComponent } from './asset-management-system/asset-management-system.component';
//Job Applications
import { JobApplicationsComponent } from './job-applications/job-applications.component';
import { JobApplicationDetailComponent } from './job-application-detail/job-application-detail.component';
//tasks
import { ListComponent } from './tasks/list/list.component';
//Git codecommit repository list
import { RepositoryListComponent } from './codecommit/repository-list/repository-list.component';
import { ViewRepositoryComponent } from './codecommit/view-repository/view-repository.component';
import { NewLogsComponent } from './codecommit/new-logs/new-logs.component';

//Notes
import { NotesComponent } from './notes/list/notes.component';
import { ViewComponent } from './notes/view/view.component';


//Build
import { UploadBuildComponent } from './build/upload-build/upload-build.component';
import { BuildHistoryComponent } from './build/build-history/build-history.component';
//www-monitor
import { MonitorListComponent } from './www-monitor/list/list.component';
import { WebsiteLogsComponent } from './www-monitor/logs/logs.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ValiLogsComponent } from './vali/vali-logs/vali-logs.component';
import { AutomatedTestingListComponent } from './automated-testing/list/list.component';
import { AutomatedTestingViewComponent } from './automated-testing/view/view.component';
import { NewReportComponent } from './report/new-report/new-report.component';




const appRoutes: Routes = [
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  // { path: 'settings', component: SettingsComponent },
  { path: 'project/list', component: ProjectListComponent },
  { path: 'project/view', component: ProjectViewComponent },
  { path: 'project/add', component: ProjectAddComponent },
  { path: 'project/edit', component: ProjectEditComponent },
  { path: 'user/list', component: UserListComponent },
  { path: 'user/view', component: UserViewComponent },
  { path: 'user/add', component: UserAddComponent },
  { path: 'user/edit', component: UserEditComponent },

  
  // { path: 'task/list', component: TaskListComponent },
  // { path: 'task/view', component: TaskViewComponent },
  // { path: 'task/add', component: TaskAddComponent },
  // { path: 'task/edit', component: TaskEditComponent },
  { path: 'report/daily', component: DailyComponent },
  { path: 'report/weekly', component: WeeklyComponent },
  { path: 'report/monthly', component: MonthlyComponent },
  { path: 'report/stats', component: StatsComponent },
  { path: 'report/new-report', component: NewReportComponent },
  { path: 'baltoro/baltoro-dashboard', component: BaltoroDashboardComponent },
  { path: 'baltoro/view-log', component: ViewLogComponent },
  { path: 'histogram', component: HistogramComponent },
  { path: 'asset-management-system', component: AssetManagementSystemComponent },
  { path: 'job-applications', component: JobApplicationsComponent },
  { path: 'job-application-detail', component: JobApplicationDetailComponent },
  { path: 'tasks/list', component: ListComponent },
  { path: 'codecommit/repository-list', component: RepositoryListComponent },
  { path: 'codecommit/view-repository', component: ViewRepositoryComponent },
  {path :'codecommit/new-logs', component:NewLogsComponent},
  { path: 'notes/list', component: NotesComponent },
   {path : 'notes/view', component:ViewComponent},
  { path: 'build/upload-build', component: UploadBuildComponent },
  { path: 'build/build-history', component: BuildHistoryComponent },
  { path: 'www-monitor/list', component: MonitorListComponent },
  { path: 'www-monitor/logs', component: WebsiteLogsComponent },
  { path: 'vali-logs', component: ValiLogsComponent },
  { path: 'automated-testing/list', component: AutomatedTestingListComponent },
  { path: 'automated-testing/view', component: AutomatedTestingViewComponent },
 
  { path: '**', pathMatch: 'full',component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MainpanelComponent,
    DashboardComponent,
    SettingsComponent,
    ProjectListComponent,
    ProjectViewComponent,
    ProjectAddComponent,
    ProjectEditComponent,
    UserListComponent,
    UserViewComponent,
    UserAddComponent,
    UserEditComponent,
    // TaskListComponent,
    // TaskViewComponent,
    // TaskAddComponent,
    // TaskEditComponent,
    FromNowPipe,
    LocalDatePipe,
    yearPipe,
    NumberFormaterPipe,
    LoginComponent,
    WeeklyComponent,
    MonthlyComponent,
    WeeklyComponent,
    MonthlyComponent,
    ViewLogComponent,
    
    BaltoroDashboardComponent,
    DailyComponent,
    HistogramComponent,
    AssetManagementSystemComponent,
    JobApplicationsComponent,
    JobApplicationDetailComponent,
    ListComponent,
    StatsComponent,
    RepositoryListComponent,
    ViewRepositoryComponent,
    NotesComponent,
    UploadBuildComponent,
    BuildHistoryComponent,
    MonitorListComponent,
    WebsiteLogsComponent,
    PageNotFoundComponent,
    ValiLogsComponent,
    AutomatedTestingListComponent,
    AutomatedTestingViewComponent,
    KeyValuePipe,
    StrReplacePipe,
    ViewComponent,
    NewLogsComponent,
    NewReportComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    ChartsModule,
    PickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    AngularMultiSelectModule,
    QuillModule,
    MatSelectModule,
    ImageCropperModule,
    StarRatingModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, useHash: true }
    )
  ],
  providers: [
    AppService,
    JobApplicationsService,
    AuthenticateService,
    NotificationService,
    CodeCommitService,
    DashboardService,
    AppBuildService,
    ProjectService,
    WebsiteService,
    BaltoroService,
    AssetsService,
    RatingService,
    TasksService,
    NotesService,
    UserService,
    TaskService,
    ValiService,
    AutomatedTestingService,
    CookieService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
