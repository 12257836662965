import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class DashboardService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {
  }

  getCounts() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/dashboard/getallcounts';
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getUserCounts(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getusercounts?id=${id}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTopProjects() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/dashboard/gettopprojects';
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTaskTimeline(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/gettasktimeline?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getUsersTimeline(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getuserstimeline?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getProjectsTimeline(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getprojectstimeline?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getProjectsReport(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getprojectsreport?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getUsersReport(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getusersreport?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  // getProjectsFilter(startDate, endDate) {
  //   let options = this.authService.setHeaderAuth();
  //   let request = this.baseApiUrl + `api/dashboard/getprojectsbyfilter?startDate=${startDate}&endDate=${endDate}`;
  //   // let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*" });
  //   // let options = new RequestOptions({ headers: headers });
  //   return this.http.get(request, options).pipe(map(response => { return response.json() }));
  // }

  getDailyReport(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getdailyreport?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getFilterReportByUser(startDate, endDate ,id , filterserverityType) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getfilterreportbyuser?startDate=${startDate}&endDate=${endDate}&id=${id}&status=${filterserverityType} `;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getLatePeopleReport(date) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getlatereport?date=${date}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTopUsers() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/dashboard/gettopusers';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTaskEstReport(filter) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/gettaskestreport?filter=${filter}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getActiveProjects(utcOffSet) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getactiveproj?utcOffSet=${utcOffSet}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getProjectsBaseReport(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/dashboard/getprojectsbasereport?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
}
